/* eslint-disable */
import gsap from "gsap";
import LazyLoad from "vanilla-lazyload";
import Flickity from "flickity";
import inView from "in-view";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

class App {
  constructor() {
    this.lazyload = new LazyLoad({
      unobserve_entered: true,
      elements_selector: ".js-lazy",
      threshold: window.innerHeight * 0.8,
      // eslint-disable-next-line camelcase
      callback_enter: ($el) => {
        if ($el.classList.contains("js-lazy-error-placeholder")) {
          $el.lazyLoadPlaceholder = $el.src;
        } else if ($el.dataset.src == undefined) {
          const figure = $el.closest("figure");
          if (figure) figure.classList.add("img-is-loaded");
        }
      },
      callback_loaded: (el) => {
        const figure = el.closest("figure");
        if (figure) figure.classList.add("img-is-loaded");
        window.dispatchEvent(new Event("scroll"));
      },
      // eslint-disable-next-line camelcase
      callback_error: ($el) => {
        if ($el.lazyLoadPlaceholder) {
          $el.src = $el.lazyLoadPlaceholder;
        }
      },
    });

    this.logoAnim();
    this.scrollAnchor();
    this.nav();
    this.carousel();
    this.parallax();
    this.inViewport();
  }

  logoAnim() {
    const tlLogo = gsap.timeline({ paused: true });
    const logo = document.querySelector(".main-header .icon--logo");
    tlLogo
      .to(logo.querySelectorAll(".chars > *"), {
        duration: 0.1,
        autoAlpha: 0,
        stagger: {
          from: "end",
          each: 0.01,
        },
      })
      .fromTo(
        logo.querySelector(".rect-gradient"),
        {
          scale: 0,
          autoAlpha: 1,
          transformOrigin: "50% 50%",
        },
        {
          duration: 0.4,
          ease: "expo.out",
          scale: 1,
          transformOrigin: "50% 50%",
        },
        "finish"
      )
      .to(
        logo.querySelectorAll(".frago > *"),
        {
          duration: 0.4,
          ease: "expo.out",
          fill: "#FFF",
        },
        "finish"
      );

    let oldScrollY = 0;
    window.addEventListener("scroll", () => {
      if (oldScrollY > window.scrollY && window.scrollY < 10) {
        // Up
        tlLogo.pause().reverse();
      } else if (oldScrollY > 10) {
        // Down
        tlLogo.pause().play();
      }

      oldScrollY = window.scrollY;
    });
  }

  inViewport() {
    // In viewport
    inView.offset(window.innerHeight * 0.1);
    inView(".js-reveal:not(.is-visible), [data-scroll]")
      .on("enter", function (elt) {
        elt.dispatchEvent(new Event("inview"));
        elt.classList.add("is-visible");
        elt.classList.remove("not-visible");

        const video = elt.querySelector("video");
        if (
          (elt.tagName == "VIDEO" &&
            elt.dataset.autoplay != "" &&
            elt.dataset.autoplay != undefined) ||
          (video &&
            video.dataset.autoplay != "" &&
            video.dataset.autoplay != undefined)
        ) {
          const v = video || elt;
          v.play();
        }
      })
      .on("exit", function (elt) {
        const video = elt.querySelector("video");
        if (
          (elt.tagName == "VIDEO" &&
            elt.dataset.autoplay != "" &&
            elt.dataset.autoplay != undefined) ||
          (video &&
            video.dataset.autoplay != "" &&
            video.dataset.autoplay != undefined)
        ) {
          const v = video || elt;
          v.pause();
        }

        elt.dispatchEvent(new Event("outview"));
        elt.classList.add("not-visible");
      });
  }

  scrollAnchor() {
    // Detect if a link's href goes to the current page
    function getSamePageAnchor(link) {
      if (
        link.protocol !== window.location.protocol ||
        link.host !== window.location.host ||
        link.pathname !== window.location.pathname ||
        link.search !== window.location.search
      ) {
        return false;
      }

      return link.hash;
    }

    // Scroll to a given hash, preventing the event given if there is one
    function scrollToHash(hash, e) {
      const elem = hash ? document.querySelector(hash) : false;
      if (elem) {
        if (e) e.preventDefault();
        gsap.to(window, { scrollTo: elem, ease: "expo.out", duration: 0.8 });
      }
    }

    // If a link's href is within the current page, scroll to it instead
    document.querySelectorAll("a[href]").forEach((a) => {
      a.addEventListener("click", (e) => {
        scrollToHash(getSamePageAnchor(a), e);
      });
    });

    // Scroll to the element in the URL's hash on load
    scrollToHash(window.location.hash);
  }

  nav() {
    const navs = document.querySelectorAll(".main-nav ul");

    if (navs) {
      navs.forEach((nav) => {
        const links = nav.querySelectorAll("a");
        const cursor = nav.querySelector(".cursor");
        let current = -1;
        let oldScrollY = 0;

        if (links && cursor) {
          window.addEventListener("scroll", (e) => {
            for (let i = 0; i < links.length; i++) {
              const index = links.length - i - 1;
              const link = links[index];

              const target = document.querySelector(
                link.getAttribute("href").replace("/", "")
              );

              if (target) {
                const bound = target.getBoundingClientRect();
                if (bound.top - window.innerHeight * 0.1 <= 0) {
                  // if (current != index) {
                  const boundParent = nav.getBoundingClientRect();
                  const boundLink = link.getBoundingClientRect();

                  gsap.to(cursor, {
                    duration: 0.3,
                    width: boundLink.width,
                    x: boundLink.x - boundParent.x,
                    ease: "none",
                  });

                  if (links[current]) links[current].classList.remove("active");
                  links[index].classList.add("active");
                  // }
                  current = index;

                  break;
                }
              }
            }

            oldScrollY = window.scrollY;
          });
        }
      });
    }
  }

  carousel() {
    const carousels = document.querySelectorAll(".carousel");

    if (carousels)
      carousels.forEach((carousel) => {
        function checkIfEnough(c, f) {
          if (c.classList.contains("carousel-imgs")) {
            const w = f.size.width;
            const wCell = f.cells[0].element.offsetWidth;
            const nbVisibleCells = Math.round(w / wCell);

            if (nbVisibleCells >= f.cells.length) {
              f.options.draggable = false;
              c.classList.add("no-slide");
              f.updateDraggable();
            } else {
              f.options.draggable = true;
              f.updateDraggable();
              c.classList.remove("no-slide");
            }
          }
        }

        const flkty = new Flickity(carousel, {
          cellAlign: "left",
          contain: true,
          on: {
            ready() {
              checkIfEnough(carousel, this);
            },
            resize() {
              checkIfEnough(carousel, this);
            },
            select(index) {
              if (carousel.classList.contains("carousel-imgs")) {
                const w = this.size.width;
                const wCell = this.cells[0].element.offsetWidth;
                const nbVisibleCells = Math.round(w / wCell);

                this.cells.forEach((cell, i) => {
                  if (i >= index && i < index + nbVisibleCells) {
                    cell.element.classList.add("is-cell-visible");
                  } else {
                    cell.element.classList.remove("is-cell-visible");
                  }
                });

                if (index == 0)
                  this.prevButton.element.classList.add("disabled");
                else this.prevButton.element.classList.remove("disabled");

                if (index + nbVisibleCells >= this.cells.length)
                  this.nextButton.element.classList.add("disabled");
                else this.nextButton.element.classList.remove("disabled");
              }
            },
          },
        });
      });
  }

  parallax() {
    const elts = document.querySelectorAll(".js-parallax");

    if (elts) {
      elts.forEach((el) => {
        gsap.to(el, {
          scrollTrigger: {
            trigger: el,
            scrub: true,
          },
          y: (i, target) => {
            return -ScrollTrigger.maxScroll(window) * target.dataset.speed;
          },
          x: (i, target) => {
            if (target.dataset.x && target.dataset.x != "") {
              return -ScrollTrigger.maxScroll(window) * target.dataset.speed;
            }
          },
          ease: "power1.out",
        });
      });
    }
  }
}

window.addEventListener("load", () => {
  new App();
  window.dispatchEvent(new Event("scroll"));
});
